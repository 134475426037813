import _ from 'lodash';
import moment from 'moment';

import { PeopleRelationType } from '../../../react/shared/models/people';
import { getCountryTaxRatesWithLabels } from '../shared/country-tax-rates';

function FormBuilderController(
  $scope,
  $timeout,
  $state,
  $filter,
  $stateParams,
  cdApp,
  Forms,
  Authorization,
  stepsFactory,
  gettextCatalog,
  $uibModal,
  $q,
  toastr,
  dateFormatsLookup,
  Me,
  Users,
  Projects,
  Church,
  PeopleCustomFields,
  formioCustomService,
  formioComponents,
  StripeAccount,
  Resources,
  FeatureToggleService
) {
  let $ctrl = this;

  const canCreateCustomField =
    Authorization.hasPermission('canAdministerUsers');

  $ctrl.hasContributionPackage = Authorization.hasPackage('contributions');
  /**
   * Prevent removing ticket components that have received payments
   */
  const nonRemovableTickets = _($ctrl.form.getTicketComponents())
    .filter((ticketComponent) => {
      if (!_.get(ticketComponent, 'churchdesk.ticket.price')) return;
      return _($ctrl.formResponses)
        .map((formResponses) => _.get(formResponses.data, ticketComponent.path))
        .some((purchased) => purchased > 0);
    })
    .map('key')
    .value();

  // Check if submission has been received, if so then dont alllow to change the payment method
  $ctrl.isFormResponded = _.get($ctrl.form, 'responseCount', 0) > 0;

  // Create custom fields on the fly
  $ctrl.cdFormBuilderCtrl = {
    canCreateCustomField,
    createCustomField: (type) =>
      $q((resolve, reject) => {
        if (!canCreateCustomField) return reject();

        $uibModal
          .open({
            component: 'cdCreateCustomFieldModal',
            resolve: {
              type: () => type,
            },
          })
          .result.then((createdKey) => {
            const customPeopleFieldKey = `peopleCustomField${createdKey}`;

            PeopleCustomFields.get().$promise.then((customFieldsForm) => {
              // Get the list of custom field components
              const customFields = _.flatMap(
                customFieldsForm.components,
                (component) => component.components || component
              );

              // Process all the dynamic components again after a new custom field has been added
              formioCustomService.registerCustomComponents(
                customFields,
                'people',
                gettextCatalog
              );

              // Return the object representing the custom field
              const newCustomFieldSettings = _.get(formioComponents, [
                'components',
                customPeopleFieldKey,
                'settings',
              ]);

              resolve(newCustomFieldSettings);
            });
          })
          .catch(reject);
      }),

    // Tickets
    nonRemovableTickets,
  };

  $ctrl.environment = cdApp.config.environment;
  $ctrl.organizationId = cdApp.organization.id;
  $ctrl.organizationCountry = cdApp.organization.countryIso2;
  $ctrl.organizationCurrency = cdApp.organization.currency;
  $ctrl.organizationName = cdApp.organization.name;

  /**
   * Lifecycle hook used for initialization work
   */
  $ctrl.$onInit = function () {
    $ctrl.users = Users.query((usersRes) => {
      const organization = {
        key: 'organization',
        name: _.get(cdApp, 'organization.name'),
        email: _.get(cdApp, 'organization.email', 'no-reply@churchdesk.com'),
        isFirst: true,
      };

      const users = _(usersRes)
        .map((user) => {
          if (!user.status) return null;

          return {
            key: `user-${user.id}`,
            name: $filter('getName')(user),
            email: user.email,
          };
        })
        .compact()
        .value();

      $ctrl.confirmationSenders = _.concat([organization], users);
    });

    $ctrl.churches = [];
    $ctrl.showChurchSelector = cdApp.showChurchSelector;
    Resources.getChurchesV3({
      permissionContext: 'form',
      permissionType: 'access',
    }).$promise.then(({ churches }) => {
      $ctrl.churches = _.filter(churches, { access: true });
      if (!$ctrl.showChurchSelector && !$ctrl.form.id) {
        $ctrl.form.churches = _.map($ctrl.churches, 'id');
      }
    });

    $ctrl.availableVATRates = getCountryTaxRatesWithLabels(
      $ctrl.organizationCountry
    );
    if (!$ctrl.availableVATRates.find((el) => el.value === null)) {
      $ctrl.availableVATRates.push({
        label: gettextCatalog.getString('None'),
        value: null,
      });
    }
    if ($ctrl.availableVATRates.length === 0) {
      $ctrl.shouldShowVatRatesDropdown = false;
    } else {
      FeatureToggleService.hasFeature('vat_rate_selector').then(
        (hasFeature) => {
          $ctrl.shouldShowVatRatesDropdown = hasFeature;
          $ctrl.shouldShowVatCheckbox = !hasFeature;
        }
      );
    }

    // Ensure form.selectedVATRate is always a number or null
    $ctrl.form.selectedVATRate = $ctrl.form.selectedVATRate || null;
    $ctrl.getSelectedVATRateObject = () =>
      $ctrl.availableVATRates.find(
        (rate) => rate.value === $ctrl.form.selectedVATRate
      ) || null;
    $ctrl.setFormVATRate = () => {
      const selectedRate = $ctrl.getSelectedVATRateObject();
      $ctrl.form.selectedVATRate = selectedRate ? selectedRate.value : null;
    };
    $ctrl.selectedVATRatePlaceholder = $ctrl.availableVATRates.find(
      (rate) => rate.value === null
    );

    // Get active donation projects for the form builder settings
    Projects.getActiveDonations((contributionProjects) => {
      $ctrl.contributionPlaceholder = {
        id: 'CONTRIBUTION_PLACEHOLDER',
        title: gettextCatalog.getString('Choose a donation project...'),
        cssClass: 'text-muted',
      };

      $ctrl.contributionProjects = [
        $ctrl.contributionPlaceholder,
        ..._.sortBy(contributionProjects.items, 'title'),
      ];

      $ctrl.setFormContribution = () => {
        if ($ctrl.form.contributionId === $ctrl.contributionPlaceholder.id) {
          $ctrl.form.contributionId = null;
        }
        $ctrl.form.contribution = _.find($ctrl.contributionProjects, {
          id: $ctrl.form.contributionId,
        });
      };
      $ctrl.setFormContribution();
    });
    /**
     * Check for if save has been clicked
     */
    $ctrl.busy = false;

    /**
     * Load church related details
     */
    $ctrl.isLoadingChurchDetails = true;
    $q.all([
      /**
       * Privacy policy
       */
      Church.get().$promise.then((church) => {
        $ctrl.church = church;
      }),
      /**
       * Whether the organization has submitted MVR
       */
      StripeAccount.get({ id: cdApp.organization.id })
        .$promise.then(() => {
          $ctrl.hasStripeAccount = true;
        })
        .catch(() => {
          $ctrl.hasStripeAccount = false;
        }),
    ]).then(() => {
      $ctrl.isLoadingChurchDetails = false;
    });

    $ctrl.mode = $stateParams.id ? 'edit' : 'create';

    $ctrl.datepicker = {
      settings: {
        start: {
          minDate: moment().toDate(),
        },

        end: {
          minDate: $ctrl.form.startDate || moment().toDate(),
        },
      },

      format: dateFormatsLookup.getFormat(),
      placeholder: dateFormatsLookup.getFormat(),
    };
    $ctrl.timePickerOptions = {
      step: 15,
      timeFormat: 'H:i',
      appendTo: 'body',
      asMoment: true,
    };

    if ($ctrl.mode === 'edit') {
      lookForEmailFields();
      identifyPeopleSectionComponents();
      $ctrl.form.startDate = $ctrl.form.startDate
        ? new Date($ctrl.form.startDate)
        : null;
      $ctrl.form.endDate = $ctrl.form.endDate
        ? new Date($ctrl.form.endDate)
        : null;
      $ctrl.form.startTime = $ctrl.form.startDate
        ? new Date($ctrl.form.startDate)
        : null;
      $ctrl.form.endTime = $ctrl.form.endDate
        ? new Date($ctrl.form.endDate)
        : null;
      const formConfirmationSender = $ctrl.form.confirmationSender;
      if (formConfirmationSender) {
        $ctrl.confirmationSender =
          formConfirmationSender.entity === 'organization'
            ? 'organization'
            : `user-${formConfirmationSender.entityId}`;
      } else {
        $ctrl.confirmationSender = cdApp.organization.email
          ? 'organization'
          : `user-${Me.id}`;
      }

      if (Authorization.hasPackage('contributions')) {
        // Initialize payment method if it hasn't been set before in that form
        if (!$ctrl.form.paymentMethod) {
          $ctrl.form.paymentMethod = 'online';
        }
      } else {
        $ctrl.form.paymentMethod = 'cash';
      }
    } else {
      /**
       * Creating a new form
       */

      // Default the payment method to 'Credit card'
      $ctrl.form.paymentMethod = 'online';
      if (Authorization.hasPackage('contributions')) {
        $ctrl.form.paymentMethod = 'online';
      } else {
        $ctrl.form.paymentMethod = 'cash';
      }

      $ctrl.confirmationSender = cdApp.organization.email
        ? 'organization'
        : `user-${Me.id}`;
    }

    $ctrl.originalForm = angular.copy($ctrl.form);

    // Add relationship settings
    $ctrl.familyRelations = [
      {
        key: PeopleRelationType.UNSPECIFIED,
        value: gettextCatalog.getString('Not specified'),
      },

      {
        key: PeopleRelationType.CHILD,
        value: gettextCatalog.getString('Child'),
      },

      {
        key: PeopleRelationType.PARENT,
        value: gettextCatalog.getString('Parent'),
      },
    ];
  };

  const ordinalWords = {
    1: gettextCatalog.getString('First'),
    2: gettextCatalog.getString('Second'),
    3: gettextCatalog.getString('Third'),
    4: gettextCatalog.getString('Fourth'),
    5: gettextCatalog.getString('Fifth'),
    6: gettextCatalog.getString('Sixth'),
    7: gettextCatalog.getString('Seventh'),
    8: gettextCatalog.getString('Eighth'),
    9: gettextCatalog.getString('Ninth'),
    10: gettextCatalog.getString('Tenth'),
    11: gettextCatalog.getString('Eleventh'),
    12: gettextCatalog.getString('Twelfth'),
  };

  /**
   * Determine whether the form is being edited or not
   */
  $ctrl.isEditingForm = function () {
    return (
      $ctrl.form.editable || (!$ctrl.form.editable && $ctrl.forceFormUpdate)
    );
  };

  $ctrl.getOrdinal = (index) => {
    if (ordinalWords[index + 1]) return ordinalWords[index + 1];
    return '';
  };

  /**
   * Generate the steps view
   */
  $ctrl.stepsInstance = new stepsFactory([
    {
      key: 'builder',
      title: gettextCatalog.getString('Build the form'),
      description: gettextCatalog.getString(
        'Create, edit and customize your form'
      ),

      isEnabled: true,
      isShown: true,
    },

    {
      key: 'settings',
      title: gettextCatalog.getString('Form settings'),
      description: gettextCatalog.getString(
        'Confirmation message, notifications and tags'
      ),

      isEnabled: true,
      isShown: true,
    },
  ]);

  /**
   * Change step based on the `step` query parameter
   */
  function goToStepAccordingToUrlQuery() {
    if (
      $stateParams.step &&
      _.includes(_.map($ctrl.stepsInstance.steps, 'key'), $stateParams.step)
    ) {
      $ctrl.stepsInstance.go($stateParams.step);
    }
  }
  goToStepAccordingToUrlQuery();
  $ctrl.uiOnParamsChanged = goToStepAccordingToUrlQuery;

  /**
   * Check if a form can be saved
   */
  $ctrl.formCanBeSaved = function () {
    let isChurchFieldsValid = true;
    if ($ctrl.showChurchSelector && $ctrl.churches.length) {
      isChurchFieldsValid = !_.isEmpty($ctrl.form.churches);
    }
    const formControls = ['title', 'startDate', 'endDate', 'maxSubmissions'];
    const conditions = [
      _.size($ctrl.form.components) > 1,
      _.every(
        formControls,
        (formControl) =>
          _.get($ctrl.formBuilderForm, `${formControl}.$valid`) === true
      ),

      !$ctrl.formHasInvalidTickets(),
      !$ctrl.isLoadingChurchDetails,
      isChurchFieldsValid,
    ];

    return _.every(conditions, (c) => c === true);
  };

  /**
   * Validate that ticket fields exist alongside at least 1 person section with those 3 required fields:
   * - Firstname
   * - Lastname
   * - Email
   */
  $ctrl.formHasInvalidTickets = () => {
    if (!$ctrl.form.hasTicketsWithPrice()) return false;

    const formPersonSectionsCount =
      _($ctrl.form.components).countBy('type').get('person') || 0;

    if (formPersonSectionsCount !== 1) {
      return true;
    }

    const personSection = _.find($ctrl.form.components, { type: 'person' });

    const hasAllRequiredFields = _.every(
      ['peopleFirstName', 'peopleLastName', 'peopleEmail'],
      (requiredFieldType) =>
        _.chain(personSection.components)
          .find({ type: requiredFieldType })
          .get('validate.required')
          .value() === true
    );

    return !hasAllRequiredFields;
  };

  /**
   * Get conflicts caused by person sections having 2 or more components of the same type
   */
  function getFormConflicts(form) {
    // Find duplicated components in the people sections
    return _(form.components)
      .filter({ type: 'person' })
      .reduce(function (result, section) {
        let duplicates = _(section.components)
          .groupBy('type')
          .filter(function (n) {
            return n.length > 1;
          })
          .flatten()
          .uniqBy('type')
          .value();

        if (duplicates.length) {
          result.push({
            section: section,
            duplicates: duplicates,
          });
        }
        return result;
      }, []);
  }

  /**
   * Check if a form has conflicts of any type, and open a confirmation modal if it does
   */
  const checkForConflicts = () => {
    const conflicts = getFormConflicts($ctrl.form);
    if (!conflicts.length) return $q.resolve();

    toastr.warning(
      gettextCatalog.getString(
        'One or more conflicts prevented the form from being saved.'
      )
    );

    return $uibModal.open({
      component: 'formConflictsModal',
      resolve: {
        conflicts: function () {
          return conflicts;
        },
      },
    }).result;
  };

  /**
   * If form has paid tickets, check if organization's tax percentage is set
   */
  const checkFormPayment = () => {
    const hasTickets = $ctrl.form.hasTicketsWithPrice();
    const hasDonation = !_.isEmpty($ctrl.form.contributionId);

    /**
     * For tickets, if VAT is required, it must be set by the user
     */
    const isTaxPercentageSet = !_.isNil($ctrl.church.taxPercentage);
    let setVatPromise = $q.resolve();
    if (hasTickets && $ctrl.form.requireVAT && !isTaxPercentageSet) {
      setVatPromise = $uibModal
        .open({
          component: 'formTaxPercentageModal',
        })
        .result.then((taxPercentage) => {
          $ctrl.church.taxPercentage = taxPercentage;
        });
    }

    /**
     * For both ticketes and donations, MVR must be submitted
     */
    return setVatPromise.then(() => {
      let hasStripeAccountPromise = $q.resolve();

      if (
        (hasTickets || hasDonation) &&
        Authorization.hasPermission('canAccessContributions') &&
        !$ctrl.hasStripeAccount
      ) {
        hasStripeAccountPromise = $uibModal.open({
          component: 'cdContributionsLegalPopup',
          resolve: { allowSkipping: true },
        }).result;
      }

      return hasStripeAccountPromise;
    });
  };
  /**
   * Save the form to the backend
   */
  const saveForm = () => {
    // allow only for one VAT-related setting to be saved, unset the other
    if ($ctrl.shouldShowVatRatesDropdown) {
      $ctrl.form.requireVAT = false;
    } else if ($ctrl.shouldShowVatCheckbox) {
      $ctrl.form.selectedVATRate = null;
    }

    // remove people section-tag mappings if they are not in the components array
    $ctrl.form.peopleSections = _.pick(
      $ctrl.form.peopleSections,
      _($ctrl.form.components).filter({ type: 'person' }).map('key').value()
    );

    // Remove donation project if payment method is cash
    if ($ctrl.form.paymentMethod === 'cash') {
      _.unset($ctrl.form, 'contribution');
      _.unset($ctrl.form, 'contributionId');
    }
    const fields = $ctrl.form.components.map((field) => {
      if (!field.key) {
        return {
          ...field,
          key: Math.random()
            .toString(36)
            .substring(0, 9)
            .replace(/[0-9]/g, 'b')
            .replace('.', 'z'),
        };
      } else {
        return field;
      }
    });
    $ctrl.form.components = fields;
    return $ctrl.form
      .$save({
        forceFormUpdate: $ctrl.forceFormUpdate,
      })
      .then(() => {
        $ctrl.originalForm = angular.copy($ctrl.form);
        toastr.success(gettextCatalog.getString('Form saved successfully.'));
        $state.go('app.private.forms.view', $ctrl.originalForm);
      })
      .catch((error) => {
        switch (_.get(error, 'data.name')) {
          case 'InvalidMaxSubmissions':
            // update the local responseCount
            $ctrl.form.responseCount = _.get(
              error,
              'data.errors.maxSubmissions'
            );

            return toastr.error(
              gettextCatalog.getString(
                'The form already received {{responseCount}} responses. Please pick a number higher than this.',
                {
                  responseCount: $ctrl.form.responseCount,
                }
              )
            );

          case 'InsufficientTickets':
            return toastr.error(
              gettextCatalog.getString(
                'Tickets were booked while you were trying to edit the form. Please refresh the page and try again.'
              )
            );

          case 'BadRequest':
            if (
              _.get(error, 'data.message') ===
              'You cannot change the VAT rate on a form with ticket payments'
            ) {
              return gettextCatalog.getString(
                'You cannot change the VAT rate on a form with ticket payments'
              );
            }

          default:
            break;
        }

        toastr.error(
          gettextCatalog.getString(
            "An error occurred and the form couldn't be saved. Please refresh the page and try again."
          )
        );
      });
  };

  /**
   * Save the form after running the necessary checks
   */
  $ctrl.save = () => {
    if (!$ctrl.formCanBeSaved()) return;

    $ctrl.busy = true;
    checkForConflicts()
      .then(() => checkFormPayment())
      .then(() => saveForm())
      .then(() => {
        $ctrl.busy = false;
      })
      .catch(() => {
        $ctrl.busy = false;
      });
  };

  /**
   * Revert the confirmation message to its original value
   */
  $ctrl.revertToOriginal = function () {
    if ($ctrl.form.message !== Forms.defaultConfirmationMessage) {
      $uibModal
        .open({
          component: 'cdSimpleModal',
          resolve: {
            title: function () {
              return gettextCatalog.getString(
                'Revert to the original message?'
              );
            },
            body: function () {
              return gettextCatalog.getString('Your changes will be lost.');
            },
            options: {
              confirmButtonText: gettextCatalog.getString('Revert message'),
              closeButtonText: gettextCatalog.getString('Cancel'),
              confirmButtonType: 'danger',
            },
          },
        })
        .result.then(function () {
          $ctrl.form.message = Forms.defaultConfirmationMessage;
        });
    }
  };

  /**
   * Lifecycle hook used for checking if the user can safely exit this state
   */
  $ctrl.uiCanExit = function () {
    let fieldsToCompare = [
      'title',
      'description',
      'message',
      'startDate',
      'endDate',
      'emailFields',
      'usersToNotify',
      'peopleSections',
    ];

    // Check for changes in fieldsToCompare
    let formFieldsAreEqual = angular.equals(
      _.pick($ctrl.form, fieldsToCompare),
      _.pick($ctrl.originalForm, fieldsToCompare)
    );

    // If it's not an editable form, only check for form fields and skip components.
    if (!$ctrl.isEditingForm()) {
      if (formFieldsAreEqual) {
        return true;
      }
    } else {
      // Check for changes in form components
      // Use "angular.toJson" before comparison so we strip the object from the properties angular adds like '$$hashkey'
      let formComponentsAreEqual = angular.equals(
        angular.toJson($ctrl.form.components),
        angular.toJson($ctrl.originalForm.components)
      );

      // If there are no changes, user can exit state
      if (formFieldsAreEqual && formComponentsAreEqual) {
        return true;
      }
    }

    // If there are changes that are unsaved
    return $uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: function () {
            return gettextCatalog.getString('Want to leave without saving?');
          },
          body: function () {
            return gettextCatalog.getString('Your changes will be lost.');
          },
          options: {
            confirmButtonText: gettextCatalog.getString('Leave without saving'),
            closeButtonText: gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
            showSecondaryButton: $ctrl.formCanBeSaved(),
            secondaryButtonText: gettextCatalog.getString('Save and leave'),
            secondaryButtonType: 'success',
          },
        },
      })
      .result.then((result) => {
        if (result && result.isSecondaryButton) {
          return saveForm();
        }
        return true;
      });
  };

  /**
   * If user chooses a start date that's after the end date, fix that
   */
  $ctrl.fixEndDate = function () {
    if (moment($ctrl.form.startDate).isAfter(moment($ctrl.form.endDate))) {
      $ctrl.form.endDate = null;
      $ctrl.form.endTime = '';
    }
  };
  $ctrl.handleEndDate = function () {
    if (moment($ctrl.form.startDate).isAfter(moment($ctrl.form.endDate))) {
      $ctrl.form.endDate = null;
      $ctrl.form.endTime = '';
      toastr.error(
        gettextCatalog.getString(
          'Close form date cannot be before the open form date.'
        )
      );
    }
  };
  $ctrl.handleStartTime = function () {
    $ctrl.form.startDate = new Date(
      moment(
        `${moment($ctrl.form.startDate).format('YYYY-MM-DD')} ${moment(
          $ctrl.form.startTime
        ).format('HH:mm')}`,
        'YYYY-MM-DD HH:mm'
      ).toISOString()
    );
    $ctrl.fixEndDate();
  };
  $ctrl.handleEndTime = function () {
    $ctrl.form.endDate = new Date(
      moment(
        `${moment($ctrl.form.endDate).format('YYYY-MM-DD')} ${moment(
          $ctrl.form.endTime
        ).format('HH:mm')}`,
        'YYYY-MM-DD HH:mm'
      ).toISOString()
    );
  };
  /**
   * Custom order function that places the form author as the first element in the user list
   */
  $ctrl.orderUsersArray = function (user) {
    return user.id === ($ctrl.form.authorId || Me.id) ? user.id : user.name;
  };

  /**
   * Look for e-mail fields in the form's components
   */
  function lookForEmailFields() {
    let form = angular.copy($ctrl.form);

    let emailFields = _.flatten(
      _.reduce(
        form.components,
        function (result, component) {
          // simple e-mail components
          if (component.inputType === 'email') {
            result.push({
              label: component.label,
              value: component.key,
            });

            // look for nested e-mail components
          } else if (_.has(component, 'components')) {
            let nestedEmailFields = _(component.components)
              .filter({ inputType: 'email' })
              .map(function (field) {
                return {
                  label:
                    field.label +
                    ' (' +
                    (component.title ||
                      gettextCatalog.getString('Untitled section')) +
                    ')',
                  value: component.key + '.' + field.key,
                };
              })
              .value();

            result.push(nestedEmailFields);
          }

          return result;
        },
        []
      )
    );

    // set an array of existing e-mail fields, to be rendered in a <select>
    $ctrl.emailFields = emailFields;

    // if there is only one email field, select it, otherwise keep only the fields that actually exist in the form by doing an intersection
    $ctrl.form.emailFields =
      emailFields.length === 1 && $ctrl.mode === 'create'
        ? [emailFields[0].value]
        : _.intersection(_.map(emailFields, 'value'), $ctrl.form.emailFields);
  }

  const identifyPeopleSectionComponents = () => {
    $ctrl.peopleSectionsComponents = _.filter($ctrl.form.components, {
      type: 'person',
    });

    // Set the default relation type.
    _.each($ctrl.peopleSectionsComponents, (peopleSection) => {
      // Set a default value, if not set already.
      if (
        !$ctrl.form.peopleSections ||
        !_.get($ctrl.form.peopleSections[peopleSection.key], 'relationType', '')
      ) {
        _.set(
          $ctrl.form,
          'peopleSections[' + peopleSection.key + '].relationType',
          PeopleRelationType.UNSPECIFIED
        );
      }
    });
  };

  /**
   * Intercept the form update event from form.io
   */
  $scope.$on('formUpdate', function () {
    // Wait for the components to be added because the 'formUpdate' event is fired instantly and the form isn’t updated until later.
    $timeout(function () {
      lookForEmailFields();
      identifyPeopleSectionComponents();
    });
  });

  /**
   * Change the state dynamic params when switching between steps
   */
  $scope.$watch('$ctrl.stepsInstance.current', function (newStep, oldStep) {
    if (!angular.equals(newStep, oldStep)) {
      $state.go($state.current, { step: newStep.key });
    }
  });

  /**
   * When a new tag has been created on the fly
   * @param {Object} newTag - the new tag object
   */
  $ctrl.onTagCreated = function (newTag) {
    $ctrl.peopleTags.push(newTag);
  };

  /**
   * Update the tags of a person section in the form
   * @param {string} personSectionKey - the key of the person section to update
   * @param {Object[]} newTagsObjects - the new array of tags objects
   */
  $ctrl.updatePersonSectionTags = function (personSectionKey, newTagsObjects) {
    // store the ids of the new tags in the person section's tags array
    // using lodash's set method in case $ctrl.form.peopleSection is undefined
    _.set(
      $ctrl.form,
      'peopleSections[' + personSectionKey + '].tags',
      _.map(newTagsObjects, 'id')
    );
  };

  $ctrl.updatePersonSectionRelationType = (personSectionKey, relationType) => {
    // Set the type of the person section
    _.set(
      $ctrl.form,
      'peopleSections[' + personSectionKey + '].relationType',
      relationType
    );
  };

  // Description text-editor redactor options
  $ctrl.formDescriptionEditorOptions = {
    placeholder: gettextCatalog.getString('Write a description...'),
  };

  /**
   * Return to the previous state if there's one, otherwise return to 'all forms' view
   */
  $ctrl.cancel = () => {
    if ($ctrl.previousState) {
      $state.go($ctrl.previousState.name, $ctrl.previousState.params);
    } else {
      $state.go('app.private.forms.list');
    }
  };

  /**
   * Update the main organization e-mail
   */
  $ctrl.updateOrganizationEmail = (jsEvent) => {
    if (jsEvent) {
      jsEvent.stopPropagation();
    }

    $uibModal
      .open({
        component: 'cdUpdateOrganizationEmailModal',
        resolve: {
          organizationEmail: () => cdApp.organization.email,
        },
      })
      .result.then((organizationEmail) => {
        if (!organizationEmail) return;
        cdApp.organization.email = organizationEmail;
        _.set(
          _.find($ctrl.confirmationSenders, { key: 'organization' }),
          'email',
          organizationEmail
        );
      });
  };

  /**
   * Set the confirmation sender user id
   *
   * @param {Object} item The ui-select $item
   * @param {String} model The ui-select $model
   */
  $ctrl.onConfirmationSenderSelect = (item, model) => {
    const [entity, entityId] = model.split('-');

    $ctrl.form.confirmationSender = {
      entity,
      entityId: entityId ? parseInt(entityId, 10) : null,
    };
  };

  /**
   * Privacy link at the bottom of the form builder
   */
  $ctrl.canShowPrivacyLink = () =>
    ($ctrl.form.editable || $ctrl.forceFormUpdate) &&
    $ctrl.church &&
    $ctrl.church.$resolved;

  $ctrl.getPrivacyPolicyLink = () =>
    `<a href="${
      $ctrl.church.privacyPolicy
    }" target="_blank" rel="noopener noreferrer">${gettextCatalog.getString(
      'data privacy policy'
    )}</a>`;

  $ctrl.selectAllChurches = () => {
    $ctrl.form.churches = _.map($ctrl.churches, 'id');
  };

  $ctrl.clearAllChurches = () => {
    $ctrl.form.churches = [];
  };

  $ctrl.filterAlreadySelected = (church) =>
    !_.includes(_.map($ctrl.form.churches, 'id'), church.id);
}

FormBuilderController.$inject = [
  '$scope',
  '$timeout',
  '$state',
  '$filter',
  '$stateParams',
  'cdApp',
  'Forms',
  'Authorization',
  'stepsFactory',
  'gettextCatalog',
  '$uibModal',
  '$q',
  'toastr',
  'dateFormatsLookup',
  'Me',
  'Users',
  'Projects',
  'Church',
  'PeopleCustomFields',
  'formioCustomService',
  'formioComponents',
  'StripeAccount',
  'Resources',
  'FeatureToggleService',
];

angular.module('cdApp.forms').component('cdFormBuilder', {
  templateUrl: '@/app/forms/form-builder/form-builder.html',
  controller: FormBuilderController,
  bindings: {
    form: '<formObject',
    formResponses: '<',
    peopleTags: '<',
    previousState: '<',
  },
});
