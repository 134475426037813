import React from 'react';
import { Card, Radio, Typography, Space, Row, Col } from 'antd';
import styled from 'styled-components';

import { ContactDetails } from '@/react/people/duplicate/components/ContactDetails';
import { CdAnglesLeft, CdAnglesRight } from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';
import { DuplicateContact } from '@/react/people/duplicate/types/Types';
import { ContactSearchComponent } from '@/react/people/duplicate/components/ContactSearchComponent';
import { useContactGroup } from '@/react/people/duplicate/hooks/useContactGroup';

interface RadioGroupComponentProps {
  duplicateRecordData: DuplicateContact;
  value?: number;
  onChange?: (value: string) => void;
  onManualSelectContact?: (value: number) => void;
  manualSecondaryContactDetails?: any;
  setManualSecondaryContactDetails?: (value: any) => void;
}

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;

  .ant-card-body {
    height: 100%;
  }
`;

const StatusText = styled(Typography.Text)`
  position: absolute;
  left: 0;
  width: 100%;
`;

const StyledSpace = styled(Space)`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .ant-space-item:not(:first-child) {
    height: 100%;
    width: 100%;
    display: flex;
  }
  .ant-row {
    width: 100%;
  }
`;

export const ContactGroupComponent = (props: RadioGroupComponentProps) => {
  const {
    primaryContactLocal,
    isMergeFromContactDetails,
    getExtraMessage,
    handleRadioChange,
    getContactDetails,
  } = useContactGroup({
    duplicateRecordData: props.duplicateRecordData,
    value: props.value,
    onChange: props.onChange,
  });

  return (
    <Radio.Group
      style={{
        width: '100%',
        marginTop: '12px',
        marginRight: '0px',
      }}
      defaultValue={props?.value}
      onChange={(e) => handleRadioChange(e.target.value)}
    >
      <Row>
        <Col span={11}>
          {isMergeFromContactDetails ? (
            <ContentContainer>
              <StyledCard>
                <StyledSpace direction="vertical" style={{ width: '100%' }}>
                  <Typography.Text style={{ fontWeight: 600 }}>
                    {gettextCatalog.getString(
                      'Select contact to merge with {{$contactTwoName}}',
                      {
                        $contactTwoName:
                          `${
                            props.duplicateRecordData.contactTwo?.firstName
                          } ${props.duplicateRecordData.contactTwo?.lastName?.toLowerCase()}` ||
                          props.duplicateRecordData.contactTwo?.email,
                      },
                      'Merge Duplicate Contacts'
                    )}
                  </Typography.Text>
                  <ContactSearchComponent
                    onManualSelectContact={props.onManualSelectContact}
                    duplicateRecordData={props.duplicateRecordData}
                    getContactDetails={getContactDetails}
                    setManualSecondaryContactDetails={
                      props.setManualSecondaryContactDetails
                    }
                  />
                </StyledSpace>
              </StyledCard>
            </ContentContainer>
          ) : (
            <ContentContainer>
              <ContactDetails
                contact={props?.duplicateRecordData?.contactOne}
                primary={
                  props?.duplicateRecordData?.contactOne?.churchDeskId ===
                  primaryContactLocal?.churchDeskId
                }
                hideRadio={!!props.manualSecondaryContactDetails}
              />
              <StatusText
                style={{
                  color: getExtraMessage(
                    props?.duplicateRecordData?.contactOne?.churchDeskId ===
                      primaryContactLocal?.churchDeskId
                  ).color,
                }}
              >
                {
                  getExtraMessage(
                    props?.duplicateRecordData?.contactOne?.churchDeskId ===
                      primaryContactLocal?.churchDeskId
                  ).text
                }
              </StatusText>
            </ContentContainer>
          )}
        </Col>
        <Col
          span={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {primaryContactLocal?.churchDeskId ===
          props?.duplicateRecordData?.contactOne?.churchDeskId ? (
            <CdAnglesLeft type="secondary" style={{ fontSize: '24px' }} />
          ) : (
            <CdAnglesRight type="secondary" style={{ fontSize: '24px' }} />
          )}
        </Col>
        <Col span={11}>
          <ContentContainer>
            <ContactDetails
              contact={props?.duplicateRecordData?.contactTwo}
              primary={
                props?.duplicateRecordData?.contactTwo?.churchDeskId ===
                primaryContactLocal?.churchDeskId
              }
            />
            <StatusText
              style={{
                color: getExtraMessage(
                  props?.duplicateRecordData?.contactTwo?.churchDeskId ===
                    primaryContactLocal?.churchDeskId
                ).color,
              }}
            >
              {
                getExtraMessage(
                  props?.duplicateRecordData?.contactTwo?.churchDeskId ===
                    primaryContactLocal?.churchDeskId
                ).text
              }
            </StatusText>
          </ContentContainer>
        </Col>
      </Row>
    </Radio.Group>
  );
};
