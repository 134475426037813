import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiSearchParams, mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import {
  DuplicateContact,
  DuplicateContactResponse,
} from '@/react/people/duplicate/types/Types';
import { handleSuccessMessage } from '@/react/shared/utils';
import gettextCatalog from '@/react/services/I18nService';
import { Person } from '@/react/people/types/people';
import cdApp from '@/react/config';
import { Contact } from '@/react/shared/models/people';

export const getDuplicateContacts = () => (params: ApiSearchParams) =>
  useQuery({
    queryKey: ['getDuplicateContacts', params],
    queryFn: async () => {
      const { extraData, limit, pageNumber } = params;
      const search = extraData?.search;
      const effectivePageNumber = search ? 1 : pageNumber;
      const response = await mainApi.get<DuplicateContactResponse>(
        `people/duplicates`,
        { limit, pageNumber: effectivePageNumber, search }
      );
      if (!response.ok) {
        handleError(response);
        return {
          items: [],
          total: 0,
        };
      }
      return {
        items: response.data.duplicates,
        total: response.data.total,
      };
    },
  });

export const useDuplicateContactRecord = (
  duplicateId?: number,
  contactOneId?: number,
  contactTwoId?: number
) => {
  const { data, isLoading } = useQuery({
    queryKey: [
      'useDuplicateContactRecord',
      duplicateId,
      contactOneId,
      contactTwoId,
    ],
    queryFn: async () => {
      let response;
      if (!duplicateId && contactOneId && contactTwoId) {
        response = await mainApi.get<DuplicateContact>(
          `people/duplicates/${contactOneId}/${contactTwoId}`
        );
      } else if (!duplicateId && (contactOneId || contactTwoId)) {
        return undefined;
      } else {
        response = await mainApi.get<DuplicateContact>(
          `people/duplicates/${duplicateId}`
        );
      }

      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data;
    },
  });
  return { duplicateRecordData: data, isLoading };
};

export const useContactDetails = (id: number) => {
  const { data } = useQuery({
    queryKey: ['useContactDetails', id],
    queryFn: async () => {
      if (!id) {
        return undefined;
      }
      const response = await mainApi.get<Person>(`people/people/${id}`);
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data;
    },
  });
  return { contactDetails: data };
};

export const useMergeDuplicateContact = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      duplicateId,
      contactOneId,
      contactTwoId,
      baseContactId,
      selectedEmail,
      selectedPhoneNumber,
    }: {
      duplicateId?: number;
      contactOneId?: number;
      contactTwoId?: number;
      baseContactId: number;
      selectedEmail: string;
      selectedPhoneNumber: string;
    }) => {
      const response = await mainApi.post(`/people/duplicates/merge`, {
        duplicateId,
        contactOneId,
        contactTwoId,
        baseContactId,
        selectedEmail,
        selectedPhoneNumber,
      });
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response;
    },
    onSuccess: (res) => {
      res.ok &&
        handleSuccessMessage(
          gettextCatalog.getString('Contacts merged successfully')
        );
      queryClient.invalidateQueries({
        queryKey: ['getDuplicateContacts'],
      });
    },
  });
  return { mergeDuplicateContact: mutate };
};

export const useRejectDuplicateContactMerge = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async (duplicateId: number) => {
      const response = await mainApi.post(`/people/duplicates/reject`, {
        duplicateId: duplicateId,
      });
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getDuplicateContacts'],
      });
    },
  });
  return { rejectDuplicateContactMerge: mutate };
};

export const useContactsBySearch = ({
  initialFirstName,
  initialLastName,
  initialEmail,
}: {
  initialFirstName?: string;
  initialLastName?: string;
  initialEmail?: string;
}) => {
  const { mutate, isPending, data } = useMutation({
    mutationFn: async (searchValue: string) => {
      const churchIds = cdApp.organization.churches.map((church) => church.id);
      const response = await mainApi.post(`/people/people/search`, {
        limit: 10,
        filter: {
          churchIds: churchIds,
          comparison: 'OR',
          filters: [
            {
              type: 'text',
              property: 'fullName',
              operator: 'like',
              value:
                searchValue ||
                initialFirstName ||
                initialLastName ||
                initialEmail,
            },
            {
              type: 'text',
              property: 'firstName',
              operator: 'like',
              value:
                searchValue ||
                initialFirstName ||
                initialLastName ||
                initialEmail,
            },
            {
              type: 'text',
              property: 'lastName',
              operator: 'like',
              value:
                searchValue ||
                initialFirstName ||
                initialLastName ||
                initialEmail,
            },
            {
              type: 'text',
              property: 'email',
              operator: 'like',
              value:
                searchValue ||
                initialFirstName ||
                initialLastName ||
                initialEmail,
            },
          ],
        },
      });
      if (!response.ok) {
        handleError(response);
        return undefined;
      }
      return response.data as { people: Contact[] };
    },
  });
  return {
    getContactsListData: mutate,
    isPending,
    contactListData: data?.people,
  };
};
