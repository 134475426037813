import { Form } from 'antd';
import { useCallback, useState, useEffect } from 'react';

import {
  useContactDetails,
  useDuplicateContactRecord,
  useMergeDuplicateContact,
  useRejectDuplicateContactMerge,
} from '@/react/people/duplicate/store/duplicateContacts';
import { DuplicateContact } from '@/react/people/duplicate/types/Types';
import { navigate } from '@/react/services/StateServiceFactory';

const getSelectedPhoneNumber = (duplicateRecordData: DuplicateContact) => {
  const contactOnePhone = duplicateRecordData?.contactOne?.phoneNumber;
  const contactTwoPhone = duplicateRecordData?.contactTwo?.phoneNumber;
  const isMaster = duplicateRecordData?.contactOne?.master;

  if (contactOnePhone && contactTwoPhone) {
    return isMaster ? contactOnePhone : contactTwoPhone;
  }
  return contactOnePhone || contactTwoPhone;
};

const getSelectedEmail = (duplicateRecordData: DuplicateContact) => {
  const contactOneEmail = duplicateRecordData?.contactOne?.email;
  const contactTwoEmail = duplicateRecordData?.contactTwo?.email;
  const isMaster = duplicateRecordData?.contactOne?.master;

  if (contactOneEmail && contactTwoEmail) {
    return isMaster ? contactOneEmail : contactTwoEmail;
  }
  return contactOneEmail || contactTwoEmail;
};

export const useDuplicateContact = ({
  duplicateId,
  contactOneId,
  contactTwoId,
  stateParams,
}: {
  duplicateId?: number;
  contactOneId?: number;
  contactTwoId?: number;
  stateParams?: any;
}) => {
  let manualBaseContactId = undefined;
  if (!duplicateId && (contactOneId || contactTwoId)) {
    manualBaseContactId = contactOneId || contactTwoId;
  }
  let manualBaseContactIdFromDetails = undefined;
  if (
    !duplicateId &&
    ((contactOneId && !contactTwoId) || (!contactOneId && contactTwoId))
  ) {
    manualBaseContactIdFromDetails = contactOneId || contactTwoId;
  }

  const [manualSecondaryContactDetails, setManualSecondaryContactDetails] =
    useState<any>(null);
  const { contactDetails } = useContactDetails(manualBaseContactId);

  const [manuallySelectedContact, setManuallySelectedContact] =
    useState(undefined);

  const formattedManualBaseContactDetails = {
    churchDeskId: contactDetails?.id,
    firstName: contactDetails?.firstName,
    lastName: contactDetails?.lastName,
    email: contactDetails?.email,
    phoneNumber: contactDetails?.phone,
    picture: contactDetails?.picture?.url,
    address: {
      street: contactDetails?.street,
      city: contactDetails?.city,
      postCode: contactDetails?.zipcode,
      country: contactDetails?.countryIso2,
    },
    contactCreated: contactDetails?.createdAt,
    lastContacted: null,
    emailNotBlocked: contactDetails?.emailStatus === 'active',
    master: true,
  };

  const { duplicateRecordData, isLoading } = useDuplicateContactRecord(
    duplicateId,
    contactOneId,
    contactTwoId
  );

  const [duplicateContactMergeForm] = Form.useForm();
  const { rejectDuplicateContactMerge } = useRejectDuplicateContactMerge();

  const { mergeDuplicateContact } = useMergeDuplicateContact();

  let formInitialValue = {};
  if (!duplicateId && (contactOneId || contactTwoId)) {
    formInitialValue = {
      selectedContact: manualBaseContactId,
      selectedEmail: contactDetails?.email,
      selectedPhoneNumber: contactDetails?.phone,
    };
  } else {
    formInitialValue = {
      selectedContact: duplicateRecordData?.contactOne.master
        ? duplicateRecordData?.contactOne.churchDeskId
        : duplicateRecordData?.contactTwo.churchDeskId,
      selectedEmail: getSelectedEmail(duplicateRecordData),
      selectedPhoneNumber: getSelectedPhoneNumber(duplicateRecordData),
    };
  }

  const mergeHandler = useCallback(async () => {
    duplicateContactMergeForm.validateFields().then((values) => {
      mergeDuplicateContact({
        duplicateId: duplicateId,
        contactOneId: contactOneId || manualBaseContactId,
        contactTwoId: contactTwoId || manuallySelectedContact,

        baseContactId: values.selectedContact,
        selectedEmail: values.selectedEmail ? values.selectedEmail : undefined,
        selectedPhoneNumber: values.selectedPhoneNumber
          ? values.selectedPhoneNumber
          : undefined,
      });
    });
    if (contactOneId && contactTwoId) {
      navigate(
        'app.private.people.contacts.list',
        { ...stateParams },
        { reload: true }
      );
    } else if (!duplicateId && (contactOneId || contactTwoId)) {
      navigate(
        'app.private.people.contacts.view',
        { id: contactOneId || contactTwoId },
        { reload: true }
      );
    }
  }, [
    contactOneId,
    contactTwoId,
    duplicateContactMergeForm,
    duplicateId,
    manuallySelectedContact,
    manualBaseContactId,
    mergeDuplicateContact,
    stateParams,
  ]);

  const onManualSelectContact = (contactId: number) => {
    setManuallySelectedContact(contactId);
  };

  // Update form values when manualBaseContactDetails becomes available
  useEffect(() => {
    if (!duplicateId && (contactOneId || contactTwoId) && contactDetails) {
      duplicateContactMergeForm.setFieldsValue({
        selectedContact: manualBaseContactId,
        selectedEmail: contactDetails?.email,
        selectedPhoneNumber: contactDetails?.phone,
      });
    }
  }, [
    duplicateId,
    contactOneId,
    contactTwoId,
    contactDetails,
    manualBaseContactId,
    duplicateContactMergeForm,
  ]);

  return {
    duplicateContactMergeForm,
    duplicateRecordData: {
      ...duplicateRecordData,
      contactOne: duplicateRecordData?.contactOne?.churchDeskId
        ? {
            ...duplicateRecordData?.contactOne,
          }
        : { ...manualSecondaryContactDetails },
      contactTwo: duplicateRecordData?.contactTwo?.churchDeskId
        ? {
            ...duplicateRecordData?.contactTwo,
          }
        : {
            ...formattedManualBaseContactDetails,
          },
    },
    rejectDuplicateContactMerge,
    mergeDuplicateContact,
    formInitialValue,
    isLoading,
    mergeHandler,
    formattedManualBaseContactDetails,
    onManualSelectContact,
    manualSecondaryContactDetails,
    setManualSecondaryContactDetails,
    manualBaseContactIdFromDetails,
  };
};
