import React from 'react';
import { Button, Space, Typography } from 'antd';
import { truncate } from 'lodash';
import moment from 'moment';

import { getStateUrl } from '@/react/services/StateServiceFactory';
import gettextCatalog from '@/react/services/I18nService';
import {
  ContactType,
  DuplicateContact,
} from '@/react/people/duplicate/types/Types';
import { openDuplicateContactMergeModal } from '@/react/people/duplicate/components/DuplicateContactMergeModal';
import { useDuplicateColumns } from '@/react/people/duplicate/hooks/useDuplicateColumns';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

const { Link } = Typography;

export const GetDuplicateContactColumns = () => {
  const { openRejectConfirmModal } = useDuplicateColumns();

  const columns = [
    {
      title: gettextCatalog.getString(
        'Contact 1',
        null,
        'Merge duplicate contacts'
      ),
      dataIndex: 'contactOne',
      key: 'contactOne',
      render: (contact: ContactType) => (
        <Space>
          <CdUserAvatar picture={contact?.picture} />
          <Space size={0} direction="vertical">
            <Link
              href={getStateUrl('app.private.people.contacts.view', {
                id: contact?.churchDeskId,
              })}
              target="_blank"
            >
              {contact?.firstName || contact?.lastName
                ? `${contact?.firstName || ''} ${
                    contact?.lastName || ''
                  }`.trim()
                : contact?.email}
            </Link>
            <Typography.Text>
              {truncate(contact?.email, { length: 35 })}
            </Typography.Text>
          </Space>
        </Space>
      ),
    },
    {
      title: gettextCatalog.getString(
        'Contact 2',
        null,
        'Merge Duplicate Contacts'
      ),
      dataIndex: 'contactTwo',
      key: 'contactTwo',
      render: (contact: ContactType) => (
        <Space>
          <CdUserAvatar picture={contact?.picture} />
          <Space size={0} direction="vertical">
            <Link
              href={getStateUrl('app.private.people.contacts.view', {
                id: contact?.churchDeskId,
              })}
              target="_blank"
            >
              {contact?.firstName || contact?.lastName
                ? `${contact?.firstName || ''} ${
                    contact?.lastName || ''
                  }`.trim()
                : contact?.email}
            </Link>
            <Typography.Text>
              {truncate(contact?.email, { length: 35 })}
            </Typography.Text>
          </Space>
        </Space>
      ),
    },
    {
      title: gettextCatalog.getString(
        'Discovered at',
        null,
        'Merge duplicate contacts'
      ),
      dataIndex: 'discoveredAt',
      key: 'discoveredAt',
      render: (data: string) => moment(data).format('LLL'),
    },
    {
      key: 'mergeId',
      render: (data: DuplicateContact) => (
        <Space direction="horizontal">
          <Button
            onClick={() =>
              openDuplicateContactMergeModal({ duplicateId: data?.id })
            }
            type="primary"
          >
            {gettextCatalog.getString('Merge', null, 'Merge duplicate contact')}
          </Button>
          <Button
            onClick={() => openRejectConfirmModal(data?.id)}
            type="default"
          >
            {gettextCatalog.getString(
              'Reject',
              null,
              'Merge duplicate contact'
            )}
          </Button>
        </Space>
      ),
      width: 150,
    },
  ];
  return columns;
};
