import { useCallback, useEffect, useState } from 'react';
import { theme } from 'antd';

import gettextCatalog from '@/react/services/I18nService';
import { DuplicateContact } from '@/react/people/duplicate/types/Types';
import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

interface UseContactGroupProps {
  duplicateRecordData: DuplicateContact;
  value?: number;
  onChange?: (value: number | string) => void;
}

export const useContactGroup = ({
  duplicateRecordData,
  value,
  onChange,
}: UseContactGroupProps) => {
  const [primaryContactLocal, setPrimaryContactLocal] = useState(null);
  const { token } = theme.useToken();

  useEffect(() => {
    setPrimaryContactLocal(
      duplicateRecordData?.contactOne?.churchDeskId === value
        ? duplicateRecordData?.contactOne
        : duplicateRecordData?.contactTwo
    );
  }, [duplicateRecordData, value]);

  const getExtraMessage = (master: boolean) => {
    if (master) {
      return {
        text: gettextCatalog.getString(
          'This is the primary contact.',
          null,
          'Merge Duplicate Contacts'
        ),
        color: token.colorSuccess,
      };
    }
    return {
      text: gettextCatalog.getString(
        'This is the duplicate contact (will be merged)',
        null,
        'Merge Duplicate Contacts'
      ),
      color: token.colorError,
    };
  };
  const getContactDetails = useCallback(async (contactId: number) => {
    if (!contactId) return undefined;
    const response = await mainApi.get<any>(`people/people/${contactId}`);
    if (!response.ok) {
      handleError(response);
      return undefined;
    }
    return response.data;
  }, []);

  const isMergeFromContactDetails = !(
    (duplicateRecordData?.id &&
      duplicateRecordData?.contactOne?.churchDeskId &&
      duplicateRecordData?.contactTwo?.churchDeskId) ||
    (!duplicateRecordData?.id &&
      duplicateRecordData?.contactOne?.churchDeskId &&
      duplicateRecordData?.contactTwo?.churchDeskId)
  );

  const handleRadioChange = (value: string | number) => {
    const numericValue =
      typeof value === 'string' ? parseInt(value, 10) : value;
    const newPrimaryContact =
      numericValue === duplicateRecordData?.contactOne?.churchDeskId
        ? duplicateRecordData?.contactOne
        : duplicateRecordData?.contactTwo;

    setPrimaryContactLocal(newPrimaryContact);
    onChange?.(numericValue);
  };

  return {
    primaryContactLocal,
    isMergeFromContactDetails,
    getExtraMessage,
    handleRadioChange,
    getContactDetails,
  };
};
