'use strict';

// Redux
import { FetchUserPrivilege } from '../../../react/user/redux/actions';
import { getUserPrivilegeSelector } from '../../../react/user/redux/selectors';

import { showModal } from '@/react/angular/ReactModalBridge';

class PeopleListComponent {
  constructor(
    $scope,
    $timeout,
    $state,
    $stateParams,
    $uibModal,
    $ngRedux,
    Authorization,
    People,
    PeopleMessages,
    PeopleList,
    gettextCatalog,
    base64,
    Resources,
    toastr,
    Analytics,
    FeatureToggleService,
    _,
    cdApp,
    PeopleSegments
  ) {
    'ngInject';

    this._ = _;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.Authorization = Authorization;
    this.People = People;
    this.PeopleMessages = PeopleMessages;
    this.gettextCatalog = gettextCatalog;
    this.base64 = base64;
    this.toastr = toastr;
    this.Analytics = Analytics;
    this.Resources = Resources;
    this.$scope = $scope;
    this.cdApp = cdApp;

    this.maxTagsNumber = 5;
    this.multiTaggingOpened = false;
    this.multiChurchingOpened = false;
    this.filtersChanged = false;
    this.creatingList = false;
    this.hideCreatingListCancel = false;
    this.showCreatingListOverlay = false;
    this.messageColumnPosition = 1;
    this.peopleList = new PeopleList();
    this.FeatureToggleService = FeatureToggleService;
    this.PeopleSegments = PeopleSegments;

    // Get the search text from the parameter, if available
    this.searchText = $stateParams.search || '';

    const unsubscribe = $ngRedux.connect(
      this.mapStateToScope,
      this.mapDispatchToScope
    )(this);
    $scope.$on('$destroy', unsubscribe);
  }

  /**
   * Lifecycle hook used for initialization work
   *
   * @memberof PeopleListComponent
   */
  $onInit() {
    const list = this._.find(this.lists, (list) => {
      const listParam = this.$stateParams.list;
      return listParam === -1
        ? list.filteringType === 'organization'
        : list.id === listParam;
    });

    // Is editing
    this.editingList = !!this.$stateParams.editing || false;

    if (list) {
      this.selectList(list.id, false, true, this.editingList);
    }
    if (this.$stateParams.createList) {
      this.viewFilters();
    }
    this.canViewPeopleSensitiveData = this.Authorization.hasPermission(
      'canViewPeopleSensitiveData'
    );

    this.fetchPeople();

    // Update the URL parameter whenever the search query changes
    this.$scope.$watch('$ctrl.searchText', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        this.$state.go(this.$state.current, { search: newVal });
      }

      // Reset when the search text is clear
      if (newVal === '') this.fetchPeople();
    });
    this.FetchUserPrivilege({ permission: 'people_message.actions.send' });
    this.FetchUserPrivilege({ permission: 'person.create' });
    this.FetchUserPrivilege({ permission: 'person.delete' });
    this.FetchUserPrivilege({ permission: 'person.actions.export' });

    this.showChurchSelector = this.cdApp.showChurchSelector;
    // Get churches that the user has people access
    this.Resources.getChurches({
      permissionContext: 'people',
      permissionType: 'access',
    }).$promise.then((churches) => {
      this.churches = churches;
    });

    this.fetchRecipients = this.fetchRecipients.bind(this);
    this.refreshSegments = this.refreshSegments.bind(this);

    this.canAdministerUsers =
      this.Authorization.hasPermission('canAdministerUsers');

    this.FeatureToggleService.hasFeature('duplicate_contacts')
      .then((res) => {
        this.duplicateMergeFeatureFlag = res;
      })
      .catch(() => {
        this.duplicateMergeFeatureFlag = false;
      });
  }

  clearChurchSelection() {
    // Reset any selected people
    this.peopleList.clearSelection();
    this.fetchPeople();
  }

  // AngularJS <-> Redux mapping functions
  mapStateToScope = (state) => ({
    canCommunicate: getUserPrivilegeSelector(
      state,
      'people_message.actions.send'
    ),
    canCreatePerson: getUserPrivilegeSelector(state, 'person.create'),
    canDeletePeople: getUserPrivilegeSelector(state, 'person.delete'),
    canExportPeople: getUserPrivilegeSelector(state, 'person.actions.export'),
  });

  mapDispatchToScope = (dispatch) => ({
    FetchUserPrivilege: (permission) =>
      dispatch(FetchUserPrivilege(permission)),
  });

  // Get status message
  getStatusMessage() {
    if (this.peopleList.busy) return '';
    const totalSelected = this.peopleList.isGlobalSelect()
      ? this.peopleList.totalMatching
      : this.peopleList.selected.length;
    // All list
    if (
      this.selectedList?.filteringType === 'organization' &&
      !this.creatingList
    ) {
      return this.peopleList.selected.length
        ? this.gettextCatalog.getPlural(
            totalSelected,
            '1 contact selected of {{ total }} in total',
            '{{ $count | number }} contacts selected of {{ total }} in total',
            { $count: totalSelected, total: this.peopleList.totalMatching }
          )
        : this.gettextCatalog.getString('{{ total }} contacts in total', {
            total: this.peopleList.totalMatching,
          });
    }

    // Parishes
    if (this.selectedList?.filteringType === 'church' && !this.creatingList) {
      return this.peopleList.selected.length
        ? this.gettextCatalog.getPlural(
            totalSelected,
            '1 contact selected of {{ total }} contacts in the parish',
            '{{ $count | number }} contacts selected of {{ total }} contacts in the parish',
            { $count: totalSelected, total: this.peopleList.totalMatching }
          )
        : this.gettextCatalog.getString(
            '{{matching}} contacts in the parish of {{total}} contacts in total',
            {
              total: this.peopleList.total,
              matching: this.peopleList.totalMatching,
            }
          );
    }

    if (this.peopleList.selected.length) {
      return this.gettextCatalog.getPlural(
        totalSelected,
        '1 contact selected of {{ total }} on the list',
        '{{ $count | number }} contacts selected of {{ total }} on the list',
        { $count: totalSelected, total: this.peopleList.totalMatching }
      );
    } else {
      return this.gettextCatalog.getPlural(
        this.peopleList.totalMatching,
        '1 contact match your list criteria of {{ total }} contacts in total',
        '{{ $count | number }} contacts match your list criteria of {{ total }} contacts in total',
        { $count: this.peopleList.totalMatching, total: this.peopleList.total }
      );
    }
  }

  /**
   * Fetch the list of people
   *
   * @param {Object} filterQuery The criteria to filter people by
   * @param {Boolean} resetSearchAfter Whether we should fetch from the beginning, ignoring any existing offset
   * @param {Boolean} maintainSelection Whether to maintain the current selection
   * @memberof PeopleListComponent
   */
  fetchPeople(
    filterQuery = this.filterQuery,
    resetSearchAfter = true,
    maintainSelection = false,
    searchType = this.searchType
  ) {
    if (!maintainSelection) {
      this.peopleList.clearSelection();
    }
    this.peopleList.fetchPeople(
      filterQuery,
      resetSearchAfter,
      maintainSelection,
      searchType
    );
  }

  /**
   * Load more rows in the list of people
   *
   * @memberof PeopleListComponent
   */
  loadMore() {
    if (this._.size(this.peopleList.people) >= this.peopleList.totalMatching) {
      return;
    }

    const filterQuery = this._.isEmpty(this.searchText)
      ? undefined
      : this.getSearchFilterQuery();

    const maintainSelection =
      !this.peopleList.allPersonsSelectedInCurrentViewCheckbox;

    this.fetchPeople(filterQuery, false, maintainSelection);
  }

  /**
   * Manually search for people by name or e-mail
   *
   * @memberof PeopleListComponent
   */
  searchPeople() {
    if (!this.searchText) {
      return this.fetchPeople();
    }

    // Reset any other filters or selected list when searching
    // because we cannot combine the search and filter queries
    this.resetFilters(true);

    if (!this.filtersChanged) {
      this.filtersChanged = true;
    }

    this.peopleList.fetchPeople(this.getSearchFilterQuery(), true);
  }

  /**
   * Get a filter query that searches for people
   *
   * @memberof PeopleListComponent
   */
  getSearchFilterQuery() {
    // Use the same churches as the all list
    const allList = this._.find(this.lists, { filteringType: 'organization' });
    const churchIds = this._.get(allList, 'setup.churchIds');
    return {
      comparison: 'OR',
      churchIds,
      filters: [
        {
          type: 'text',
          property: 'fullName',
          operator: 'like',
          value: this.searchText,
        },

        {
          type: 'text',
          property: 'email',
          operator: 'like',
          value: this.searchText,
        },

        {
          type: 'text',
          property: 'phone',
          operator: 'like',
          value: this.searchText,
        },
      ],
    };
  }

  /**
   * Update the field the list is ordered by
   *
   * @param {String} orderBy
   * @memberof PeopleListComponent
   */
  updateOrdering(orderBy) {
    this.peopleList.updateOrdering(orderBy);
    this.searchPeople();
  }

  /**
   * Get the icon representing the current order direction
   *
   * @param {String} orderBy
   * @memberof PeopleListComponent
   */
  getColumnOrderIcon(orderBy) {
    if (this.peopleList.orderBy === orderBy) {
      return this.peopleList.orderDirection === 'asc'
        ? 'fa fa-sort-up fa-fw'
        : 'fa fa-sort-down fa-fw';
    }
  }

  /**
   * Update the filter criteria
   *
   * @param {String} comparator The filter comparator
   * @param {Object[]} criteria The array of filter criteria
   * @param {Boolean} refetch Whether these changes should trigger a new data fetch
   * @memberof PeopleListComponent
   */
  updateFilters(comparator, criteria, refetch, churchIds) {
    this.searchText = '';

    this.filterQuery.comparison = comparator;
    this.filterQuery.filters = criteria;
    this.filterQuery.churchIds = churchIds;
    this.checkIfFiltersChanged();

    if (refetch) {
      this.fetchPeople();

      // Delay hiding the overlay the same amount of time as
      // the debounce value inside people-list.service.js
      this.$timeout(() => {
        this.showCreatingListOverlay = false;
      }, 1000);
    }
  }

  /**
   * Check whether the filters have changed
   *
   * @memberof PeopleListComponent
   */
  checkIfFiltersChanged() {
    if (!this.selectedList) return false;

    const _ = this._;
    const propsToCompare = ['type', 'property', 'operator', 'value'];

    const equalityConditions = [
      this.filterQuery.comparison === this.selectedList.setup.comparison,
      // Compare churchIds
      _.isEqual(
        _.sortBy(this.filterQuery.churchIds),
        _.sortBy(this.selectedList.setup.churchIds)
      ),

      _.isEqualWith(
        this.filterQuery.filters,
        this.selectedList.setup.filters,
        (localFilters, listFilters) => {
          const mappedLocalFilters = _.map(localFilters, (item) =>
            _.pick(item, propsToCompare)
          );

          const mappedListFilters = _.map(listFilters, (item) =>
            _.pick(item, propsToCompare)
          );

          return _.isEqual(mappedLocalFilters, mappedListFilters);
        }
      ),
    ];

    this.filtersChanged = !_.every(
      equalityConditions,
      (condition) => condition === true
    );
  }

  /**
   * Reset filters by switching to the "all" list
   *
   * @param {Boolean} skipFetching Whether we should skip fetching people by default. Used when searching
   * @memberof PeopleListComponent
   */
  resetFilters(skipFetching = false) {
    this.filtersChanged = false;
    this.creatingList = false;
    this.showCreatingListOverlay = false;
    this.editingList = false;
    this.peopleList.resetOrdering();

    const list = _.find(this.lists, { filteringType: 'organization' });
    this.selectList(list.id, true, skipFetching);
  }

  /**
   * Show the filters to create a new list
   *
   * @memberof PeopleListComponent
   */
  viewFilters() {
    this.creatingList = true;
    this.hideCreatingListCancel = true;
  }

  editList(listId) {
    this.editingList = true;

    this.selectList(listId, true, true, true);
  }

  /**
   * Create a new list
   *
   * @memberof PeopleListComponent
   */
  createList() {
    this.$uibModal
      .open({
        component: 'cdPeopleCreateListModal',
        resolve: {
          filterQuery: () => _.cloneDeep(this.filterQuery),
          filterGroups: () => this.filterGroups,
        },
      })
      .result.then((newList) => {
        newList.filteringType = 'segment';
        newList.type = 'filter';
        newList.access = true;
        this.lists.push(newList);
        this.filtersChanged = false;
        this.selectList(newList.id);
      });
  }

  refreshSegments() {
    this.PeopleSegments.query().$promise.then((data) => {
      this.lists = data;
    });
  }

  /**
   * Update the currently selected list or create a new one
   *
   * @memberof PeopleListComponent
   */
  upsertList() {
    if (
      this.selectedList.filteringType === 'organization' ||
      this.selectedList.filteringType === 'church'
    ) {
      return this.createList();
    }
    this.selectedList.setup = angular.copy(this.filterQuery);
    this.selectedList.$save(() => {
      this.filtersChanged = false;
      this.toastr.success(this.gettextCatalog.getString('List updated.'));
      this.Analytics.sendFeatureEvent('update segment', { module: 'people' });
    });
  }

  /**
   * Update the selected list
   *
   * @param {Number} listId The id of the list to be set
   * @param {Boolean} isReset Whether the selected list should be reset, in which case we force it
   * @param {Boolean} skipFetching Whether we should skip fetching people by default. Used when searching
   * @param {Boolean} isEditing Trying to edit a list
   *
   * @memberof PeopleListComponent
   */
  selectList(listId, isReset = false, skipFetching, isEditing = false) {
    this.selectedList = this._.find(this.lists, { id: listId });
    this.searchType = this.selectedList.type;

    // Check whether custom filters are provided in the URL
    if (
      !isReset &&
      this.$stateParams.filter &&
      this.selectedList.filteringType === 'organization'
    ) {
      const decodedFilters = angular.fromJson(
        this.base64.urldecode(this.$stateParams.filter)
      );

      if (_.isArray(decodedFilters)) {
        // Use the same churches from the all list
        const churchIds = this.selectedList.setup.churchIds;
        this.filterQuery = {
          comparison: 'AND',
          churchIds,
          filters: decodedFilters,
        };

        this.filtersChanged = true;
        this.creatingList = true;
      }
    } else {
      this.filterQuery = this._.cloneDeep(this.selectedList.setup);
    }

    // Reset any selected people
    this.peopleList.clearSelection();

    // Update the URL parameters
    this.$state.go('app.private.people.contacts.list', {
      list: this.selectedList.filteringType === 'organization' ? -1 : listId,
      editing: isEditing ? 'true' : '',
      filter:
        this.selectedList.filteringType === 'organization' && !isReset
          ? this.$stateParams.filter
          : '',
    });

    if (!skipFetching) {
      this.fetchPeople();
    }
  }

  /**
   * Close the currently select list
   */
  closeList() {
    if (!this.filtersChanged) {
      return this.resetFilters();
    }

    this.$uibModal
      .open({
        component: 'cdSimpleModal',
        resolve: {
          title: () => this.gettextCatalog.getString('Close list'),
          body: () =>
            this.gettextCatalog.getString(
              "You haven't saved your changes to this list. Are you sure you want to discard your changes and close it?"
            ),

          options: {
            confirmButtonText: this.gettextCatalog.getString('Close list'),
            closeButtonText: this.gettextCatalog.getString('Cancel'),
            confirmButtonType: 'danger',
          },
        },
      })
      .result.then(() => {
        this.resetFilters();
      });
  }

  fetchRecipients(messageType) {
    let recipients;
    if (
      this.peopleList.isGlobalSelect() ||
      this._.isEmpty(this.peopleList.selected)
    ) {
      if (this.selectedList.filteringType === 'organization') return;
      recipients = [this.selectedList];
    } else {
      // Only send to people who have email or phone, based on what the messageType is
      recipients = this._.filter(this.peopleList.selected, (person) => {
        const field = this._.find(person.fields, {
          property: messageType === 'sms' ? 'phone' : 'email',
        });

        const sharedContact =
          messageType === 'sms' ? 'hasSharedPhone' : 'hasSharedEmail';
        return !this._.isNil(field.value) || person[sharedContact];
      });
    }
    return recipients;
  }

  isGlobalSelectionNotAllowed() {
    if (
      this.peopleList.isGlobalSelect() &&
      this.selectedList.filteringType === 'organization'
    ) {
      return true;
    }
    if (
      this.peopleList.isGlobalSelect() &&
      (this.filtersChanged || this.creatingList)
    ) {
      return true;
    }
    return false;
  }

  isSendingButtonDisabled() {
    if (this.isGlobalSelectionNotAllowed()) return true;
    if (
      this.selectedList?.filteringType === 'organization' &&
      !this.peopleList.selected.length
    ) {
      return true;
    }
    return (
      !this.peopleList.metadata.canSendEmail &&
      !this.peopleList.metadata.canSendSMS
    );
  }

  /**
   * Create a new contact
   *
   * @param {Function} [callback]
   * @memberof PeopleListComponent
   */
  createPeople(callback) {
    this.$uibModal
      .open({ component: 'cdCreatePeopleModal' })
      .result.then(({ newPerson, createAnother }) => {
        if (createAnother) {
          this.createPeople(this.$state.reload);
        } else {
          this.$state.go('app.private.people.contacts.view', {
            id: newPerson.id,
          });
        }
      })
      .catch(callback);
  }

  genericSelectionLabelTarget() {
    let count = this.peopleList.isGlobalSelect()
      ? this.peopleList.totalMatching
      : this.peopleList.selected.length;
    if (count <= 0) return;
    let target = this.gettextCatalog.getPlural(
      count,
      '1 contact',
      '{{ $count | number }} contacts',
      { $count: count }
    );

    return _.truncate(target, { length: 15 });
  }

  /**
   * Delete multiple people
   *
   * @memberof PeopleListComponent
   */
  deletePeople() {
    this.$uibModal
      .open({
        component: 'cdPeopleDeleteModal',
        resolve: {
          count: () =>
            this.peopleList.isGlobalSelect()
              ? this.peopleList.totalMatching
              : this.peopleList.selected.length,
          filterQuery: () => this.filterQuery,
          selectedPeople: () => this.peopleList.selected,
          isGlobalSelect: () => this.peopleList.isGlobalSelect(),
        },
      })
      .result.then(() => {
        this.$state.reload();
      });
  }

  /**
   * Export multiple people
   */
  exportPeople() {
    this.$uibModal.open({
      component: 'cdPeopleExportModal',
      resolve: {
        filterQuery: () => this.filterQuery,
        selectedPeople: () => this.peopleList.selected,
        isGlobalSelect: () => this.peopleList.isGlobalSelect(),
        columns: () =>
          this.peopleList?.columnManager?.columns
            ?.filter((column) => column?.isVisible)
            .map((column) => ({
              property: column.property,
              label: column.label,
            })),
      },

      size: 'lg',
    });
  }

  /**
   * Print the current page
   *
   * @memberof PeopleListComponent
   */
  printPage() {
    window.print();
  }

  /**
   * Show the duplicate contact merge modal
   */
  showMergeDuplicatesModal = () => {
    showModal('openDuplicateContactMergeModal', {
      contactOneId: this.peopleList.selected[0].id,
      contactTwoId: this.peopleList.selected[1].id,
      stateParams: this.$stateParams,
    });
  };

  /**
   * Navigate to a route directly from the template
   *
   * @param {String} state The name of the route
   * @param {Object} params Optional state parameters
   */
  navigate(state, params) {
    this.$state.go(state, params);
  }

  /**
   * Callback when URL parameters are changed
   */
  uiOnParamsChanged(newParams) {
    if (newParams.list) {
      if (this.searchText || this.selectedList.id === this.$stateParams.list) {
        return;
      }

      const list = this._.find(this.lists, (list) => {
        const listParam = this.$stateParams.list;
        return listParam === -1
          ? list.filteringType === 'organization'
          : list.id === listParam;
      });

      if (list) {
        return this.selectList(list.id);
      }
    }
  }

  /**
   * Add a new tag to the list of filter options
   *
   * @param {Object} tag The newly created tag
   */
  addNewTagToFilters(tag) {
    const tagFilter = _(this.filterGroups)
      .map('filters')
      .flatten()
      .find({ property: 'tags' });

    tagFilter.options = tagFilter.options || [];
    tagFilter.options.push({ value: tag.id, label: tag.name });
  }

  /**
   * Called when the tags of one or more persons have been updated
   */
  onTagsUpdated() {
    const filterQuery = this.searchText
      ? this.getSearchFilterQuery()
      : undefined;
    this.fetchPeople(filterQuery, true, true);
  }
}
PeopleListComponent.$inject = [
  '$scope',
  '$timeout',
  '$state',
  '$stateParams',
  '$uibModal',
  '$ngRedux',
  'Authorization',
  'People',
  'PeopleMessages',
  'PeopleList',
  'gettextCatalog',
  'base64',
  'Resources',
  'toastr',
  'Analytics',
  'FeatureToggleService',
  '_',
  'cdApp',
  'PeopleSegments',
];

angular.module('cdApp.people').component('cdPeopleListState', {
  templateUrl: '@/app/people/people-list/people-list.component.html',
  controller: PeopleListComponent,
  bindings: {
    lists: '<',
    filterGroups: '<',
  },
});
