import React, { useState } from 'react';
import { Space } from 'antd';

import gettextCatalog from '@/react/services/I18nService';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
import { useContacts } from '@/react/people/duplicate/hooks/useContacts';
import { DuplicateContact } from '@/react/people/duplicate/types/Types';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { Person } from '@/react/people/types/people';
interface ContactSearchComponentProps {
  onManualSelectContact: (contactId: number) => void;
  duplicateRecordData: DuplicateContact;
  getContactDetails: (contactId: number) => Promise<any>;
  setManualSecondaryContactDetails: (value: any) => void;
}

export const ContactSearchComponent: React.FC<ContactSearchComponentProps> = ({
  onManualSelectContact,
  duplicateRecordData,
  setManualSecondaryContactDetails,
  getContactDetails,
}) => {
  const { contactOptions, getContactsList, isLoading } =
    useContacts(duplicateRecordData);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const getFormattedContactDetails = (contactDetails: Person) => ({
    churchDeskId: contactDetails?.id,
    firstName: contactDetails?.firstName,
    lastName: contactDetails?.lastName,
    email: contactDetails?.email,
    phoneNumber: contactDetails?.phone,
    picture: contactDetails?.picture?.url,
    address: {
      street: contactDetails?.street,
      city: contactDetails?.city,
      postCode: contactDetails?.zipcode,
      country: contactDetails?.countryIso2,
    },
  });
  const getContactOverView = async (value: number) => {
    if (!value) return;

    setIsLoadingDetails(true);
    try {
      const contactDetails = await getContactDetails(value);
      const formattedContactDetails =
        getFormattedContactDetails(contactDetails);
      setManualSecondaryContactDetails(formattedContactDetails);
    } finally {
      setIsLoadingDetails(false);
    }
  };

  return (
    <CdSelect
      style={{ width: '100%' }}
      placeholder={gettextCatalog.getString('Search contact to merge')}
      onChange={(value) => {
        onManualSelectContact(Number(value));
      }}
      onSearch={(value) => {
        getContactsList(value);
      }}
      options={contactOptions.map((contact) => ({
        label: (
          <Space>
            <CdUserAvatar picture={contact.picture} size={30} />
            {contact.label}
          </Space>
        ),
        value: contact.value,
        searchText: contact.label,
      }))}
      onSelect={(value) => {
        getContactOverView(Number(value));
      }}
      loading={isLoading || isLoadingDetails}
    />
  );
};
