import { Col, Empty, Row, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React, { ReactNode } from 'react';

import gettextCatalog from '../../../services/I18nService';

export interface CdSelectProps extends SelectProps {
  placeholder: string;
  emptyText?: string;
  addonAfter?: ReactNode;
  disableSort?: boolean;
}

export function CdSelect({
  allowClear = true,
  showSearch = true,
  addonAfter,
  emptyText,
  ...rest
}: CdSelectProps) {
  return (
    <Row wrap={false} gutter={4} align="middle">
      <Col flex="auto">
        <Select
          allowClear={allowClear}
          showSearch={showSearch}
          filterOption={(input: string, option: any) =>
            // We support name as a string only, since label can be a ReactNode and that isn't compatiable with the filterOption function
            (option?.searchText || option?.label)
              ?.toLowerCase()
              .includes(input.toLowerCase())
          }
          filterSort={(a: DefaultOptionType, b: DefaultOptionType) =>
            (((a?.searchText || a?.label) as string) ?? '')
              .toLowerCase()
              .localeCompare(
                (((b?.searchText || b?.label) as string) ?? '').toLowerCase()
              )
          }
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                emptyText || gettextCatalog.getString('No results found')
              }
              style={{ margin: 8 }}
            />
          }
          {...rest}
        />
      </Col>
      {addonAfter && (
        <Col style={{ alignSelf: 'stretch', height: 'auto' }}>{addonAfter}</Col>
      )}
    </Row>
  );
}
